<template>
  <h1 class="spacing">Login Below</h1>
  <div class="spacing">****************************</div>
  <login @eventing="handleEvent" @user="handleUser"/>
  <buttons v-if="showButtons" :user="user"/>
</template>

<script>
import Login from './components/Login.vue'
import Buttons from './components/Buttons.vue'

export default {
  name: 'App',
  components: {
    Login,
    Buttons
  },
  data() {
    return {
      showButtons: null,
      user: ''
    }
  },
  methods: {
    handleEvent (data) {
      setTimeout(() => {
        this.showButtons = data
      }, 1000)
    },
    handleUser (data) {
      this.user = data
    }
  }  
}
</script>

<style>
body {
  background-color: black;
  font-family: 'VT323', monospace;
  font-weight: bold;
  color: #27b63a;
  text-align: left;
  padding: 20px;
  font-size: 20px;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}
.spacing {
  margin-bottom: 10px;
}
h1 {
    text-transform: uppercase;
}
</style>
