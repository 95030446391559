<template>
  <div class="server">
    turning on server for {{ user }}
  </div>
  <div v-if="offSwitch" class="server-off">
    when you're finished with the server push button
    <div class="destroy-btn" @click="disconnect">OFF</div>
  </div>
  <div v-if="goodbyeMsg">
    turning off server. thank you for playing, {{ user }}.
  </div>
</template>

<script>

export default {
  name: 'Buttons',
  props: ['user'],
  data() {
    return {
      offSwitch: null,
      goodbyeMsg: null
    }
  },
  mounted() {
    setTimeout(() => {
      this.offSwitch = true
    }, 1500)
  },
  methods: {
    disconnect() {
      this.goodbyeMsg = true
    }
  }
}
</script>

<style scoped>
  .server {
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
  }

  .destroy-btn {
    width: 50px;
    height: 25px;
    border: 3px solid #27b63a;
    padding: 5px;
    margin: 20px 0;
    text-align: center;
  }
  .destroy-btn:hover {
      color: white;
      cursor: pointer;
  }
  
</style>
