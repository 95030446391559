<template>
  <div class="hello">
    <div class="login">username: </div>
    <input v-model="username"/>
    <div class="login">password: </div>
    <input type="password" v-on:keyup.enter="checkLogin" v-model="password" />
  </div>
  <div v-if="error" class="error" :class="{ blinking : !success }">
    <span>{{ this.errorMsg }}</span>
  </div>
  <div v-if="success" class="success">
    <div>{{ this.successMsg }}</div>
    <div v-if="transfer">{{ this.transferMsg }}</div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Login',
  emits: ["eventing", "user"],
  data() {
    return {
      username: '',
      password: '',
      success: null,
      error: null,
      transfer: null,
      errorMsg: 'username and password do not compute. please try again.',
      successMsg: 'you seem to know your stuff...',
      transferMsg: 'transfering you to the server buttons...',
      apiKey: 'https://myusersnodeapi.herokuapp.com/users/full/59600d8f-a97a-451e-8acc-04e174ca4e69',
      encodedApi: ''
    }
  },
  mounted() {
    this.encodedApi = encodeURIComponent(this.apiKey)
  },
  methods: {
    checkLogin() {
      axios
        .get(decodeURIComponent(this.encodedApi))
        .then(response => {
            let output = response.data
            for (let i = 0; i < output.length; i++) {
              if (output[i].username === this.username && output[i].password === this.password) {
                this.success = true
                this.username = ''
                this.password = ''
                setTimeout(() => {
                  this.transfer = true
                  this.$emit('eventing', true)
                  this.$emit('user', output[i].username)
                }, 800)
                break
              } else {
                this.error = true
              }
            }
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>

<style scoped>
  .hello {
    display: flex;
    max-width: 250px;
    flex-wrap: wrap;
  }
  .login {
    height: 25px;
    padding-top: 2px;
  }
  input {
  background-color: black;
  border: #27b63a solid;
  border-width: 0 0 1px 0;
  height: 25px;
  font-family: 'VT323', monospace;
  color: #27b63a;
  padding: 0 5px;
  font-size: 20px;
  }
  input:focus-visible {
    outline: none;
  }
  .error {
    color: #27b63a;
    overflow: hidden;
    white-space: nowrap;
    margin: 15px 0;
    width: 440px;
  }
  .blinking {
    animation: blinking 1s infinite;
  }
  .success {
    color: #27b63a;
    overflow: hidden;
    white-space: nowrap;
    margin: 15px 0;
  }
  @keyframes blinking {
    0%, 49% { background-color: #b3ebbb }
    50%, 100% { background-color: black }
  }
</style>
